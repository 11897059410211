import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { useMyBrokerInfo } from "../../../../hooks/broker.hooks";

const ApplicationStepHeader = ({ title, index, step, setApplicationStep }) => {
  return (
    <div
      role="button"
      tabIndex="0"
      onClick={() => setApplicationStep(index)}
      className={classNames("loan-application-step", {
        "loan-application-step-selected": step === index,
      })}
    >
      {title}
    </div>
  );
};

const LoanApplicationStep = ({ step = 0, setApplicationStep = () => {} }) => {
  const { data: brokerInfo } = useMyBrokerInfo();

  const renderStepTitle = (list) => {
    return list.map((title, index) => (
      <ApplicationStepHeader
        key={title}
        title={title}
        index={index}
        step={step}
        setApplicationStep={setApplicationStep}
      />
    ));
  };
  return (
    <div className="loan-application-step-container w-100 mb-5">
      <ScrollContainer className="scroll-container w-100">
        <div className="application-step-scroll">
          {renderStepTitle([
            "Applicants",
            "Financials",
            "Document Center",
            `Send Information to ${brokerInfo.name}`,
          ])}
        </div>
      </ScrollContainer>
    </div>
  );
};

LoanApplicationStep.propTypes = {
  step: PropTypes.number,
  setApplicationStep: PropTypes.func,
};

export default LoanApplicationStep;
